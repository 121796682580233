@font-face {
    font-family: 'freight-sans-pro';
    src: url(fonts/freigsanproboo-webfont.woff2) format('woff2');
}

@font-face {
    font-family: 'freightSansLight';
    src: url(./fonts/freigsanprolig-webfont.woff2) format('woff2');
}


@font-face {
    font-family: 'freightSansSemiBold';
    src: url(./fonts/freigsanprosem-webfont.woff2) format('woff2');
}

@font-face {
    font-family: 'freightSansBlack';
    src: url(./fonts/freigtexprobla-webfont.woff2) format('woff2');
}

@font-face {
    font-family: 'GT-Super-Text-Black';
    src: url(./fonts/gt-super-text-black.woff2) format('woff2');
}

@font-face {
    font-family: 'GT-Super-Text-Book-Italic';
    src: url(./fonts/gt-super-text-book-italic.woff2) format('woff2');
}


  
body {
    font-family:  'freight-sans-pro', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-content: center; */
    /* height: 100vh; */
    /* width: 100vw; */
    margin: 0;
    padding:0;
    align-items: center;
    color: #3B3630;
}

h1, h2 {
    font-family: 'GT-Super-Text-Black','Times New Roman', Times, serif;
    color: #6E377D;
    font-weight: 500
}

h2 {
    font-size: 1.3rem;
}

h3 {
    font-family: "freightSansSemiBold", Arial, Helvetica, sans-serif;
    font-size: 1.3rem;
    color: #3B3630;
    font-weight: 500
}

h2 {
    font-size: 1.1rem;
}

header {
    min-height: 4rem;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 4px 2px 4px #00000033;
}

.logo-wrapper {
    width:80rem;
    align-self: center;
    padding: 0.5rem 0 3.5rem;
}

.logo {
    height: 2.25rem;
    width: 6rem;
}

.stepper-area {
    width:80rem;
    align-self: center;
    padding-top: 1.5rem;
}

section {
    width:80rem;
}

.stepper {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0 0 1rem 0;
}

.stepper li {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.step {
    margin-right: 0.25rem;
    background-color: #9D9D9D;
    color: white;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 0.925rem;
    font-family: 'freightSansSemiBold', Arial, Helvetica, sans-serif;
}

.step.active{
    background-color: #006E50;
    color: white;
}

.stepper-spacer {
    /* padding: 0.115rem 0rem; */
    width: 0.7rem;
    height: 0.2rem;
    background-color: #cdcdcd;
    margin-right: 0.25rem;
}

main {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 80rem;
    margin-bottom: 3rem;
}

.elementsForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
}

#submit {
    background-color: #6E377D;
    border-radius: 4rem;
    padding: 0.75rem 3rem;
}

.order-summary {
  background-color: #F5F2EE;
  border-radius: 0.5rem;
  width: 50%;
  padding: 2rem;
}

.product-card {
    background-color: white !important;
    border-radius: 0.5rem;
    border: 1px solid #E9DED1;
}

.product-card-content {
    margin-left: 35%;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
}

.orange-botanical {
    background-image: url(images/crocosmia.png);
    background-color: white !important;
    background-position: -20px -10px;
    background-size: 300px;
    background-repeat: no-repeat;
}

.product-card p {
    font-size: 1rem;
    margin: 0;
    padding: 1rem 0;
}

.product-card .title {
    font-size: 1.3rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
}

.product-card .price {
    text-align: right;
    font-size: 1.8rem;
    font-weight: 500;
}

.pricing-table {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.pricing-table li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1rem;
    font-weight: 500;
    font-size: 0.925rem;
    font-family: 'freightSansSemiBold', Arial, Helvetica, sans-serif;
}

.pricing-table .total-value {
    font-size: 1.3rem;
}

.line-break {
    height: 1px;
    width: 100%;
    background-color: #E9DED1;
    margin: 1.5rem 0;
}

.or-element {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 1rem 0;
    align-items: center;
}

.line {
    height: 1px;
    width: 100%;
    background-color: #E9DED1; 
}

.or-option {
    font-size: 1.2rem;
    font-weight: 800;
}

footer {
    background-color: #004239;
    color: white;
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

}

.footer-inner {
    width:80rem;
    align-self: center;
    padding-top: 1.5rem;
}

.footer-inner ul {
    display: flex;
    flex-direction: row;
    list-style: none;
}

.footer-inner li {
    padding-right: 2rem;
}


.homepage-heading {
    font-size: 1.3rem;
    font-weight: 900;
    padding-bottom: 1rem;
    border-bottom: 1px solid #444;
    padding-top: 4rem;
}
